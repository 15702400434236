import { createSlice } from '@reduxjs/toolkit'
import initialState from "../configs/appConfig"

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
  },
})

export default appSlice.reducer