import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios"
import initialState from "../../configs/apiConfig"
import { history } from "../../utility/history"

const executeCommand = createAsyncThunk(
  'api/execute',
  async ({method, command, params, headers}, thunkAPI) => {

    switch((method || '').toLowerCase()){
      case 'get':
        method = 'get'
        break
      case 'post':
        method = 'post'
        break
      default:
        method = thunkAPI.getState().api.conf.method
        break
    }

    if(params instanceof FormData){
      params.append("token", thunkAPI.getState().api.token)
    }
    else{
      params = { ...params, ...{token: thunkAPI.getState().api.token}, }
    }

    const config = {
      method,
      baseURL: thunkAPI.getState().api.conf.url,
      url: command,
      timeout: thunkAPI.getState().api.conf.timeout,
      ...(headers ? {headers} : null),
      ...(method === 'get' ? {params} : {data: params}),
    }

    return await axios.request(config)
    .then(response => response.data )
    .catch(() => {
      return { errors:['apiUnavailable'], data: [], token: '', }
    })
  }
)

export const apiSlice = createSlice({
  name: 'api',
  initialState: initialState,
  reducers: {
    loginWithJWT: (state, action) => {
      const { user=null, company=null} = action.payload
      return { ...state, user, company, }
    },
    logoutWithJWT: (state, action) => {
      history.push("/")
      return { ...state, user: null, company: null, }
    },
    setCompany: (state, action) => {
      state.company = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(executeCommand.pending, (state, { payload }) => {})
    .addCase(executeCommand.fulfilled, (state, { payload }) => {
      if(payload.token)
        state.token = payload.token
    })
    .addCase(executeCommand.rejected, (state, action) => {})
  },
})

export const { 
  loginWithJWT,
  logoutWithJWT,
  setCompany,
  setUser,
 } = apiSlice.actions

export {executeCommand}

export default apiSlice.reducer