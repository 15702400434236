import { applyMiddleware, compose, combineReducers } from "redux"
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import appReducer from './appSlice'
import authReducer from './api/apiSlice'

const middlewares = [thunk, createDebounce()]

const rootPersistConfig = {
  key: 'root',
  storage,
}

const appPersistConfig = {
  key: 'app',
  storage,
}

const apiPersistConfig = {
  key: 'api',
  storage,
  whitelist: [
    'token',
  ],
}

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  api: persistReducer(apiPersistConfig, authReducer),
})


const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  enhancers: [compose(applyMiddleware(...middlewares))],
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }),
})
const persistor = persistStore(store)

export {store, persistor}