import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { store, persistor} from './app/store'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from "react-toastify"
import Spinner from "./components/spinner/Fallback-spinner"
import { IntlProviderWrapper } from './utility/context/Internationalization'

const LazyApp = lazy(() => import("./App"))

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<Spinner />}>
        <IntlProviderWrapper>
          <LazyApp />
          <ToastContainer />
        </IntlProviderWrapper>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
)